<template>
  <div>
    <v-row class="py-0" dense>
      <v-col class="pb-2 pt-0">
        <TimeSelectorV2
          :disabled="dateIsBlocked"
          :initial-time="initialStartTime"
          @selectTime="emitStartTime"
          :dense="true"
        />
      </v-col>
      <v-col class="pb-2 pt-0">
        <TimeSelectorV2
          :disabled="dateIsBlocked"
          :initial-time="initialEndTime"
          @selectTime="emitEndTime"
          :dense="true"
        />
      </v-col>
      <v-col
        align-self="center"
				class="pb-2 pt-0"
      >
        <v-btn
          :disabled="dateIsBlocked"
          text
					block
          @click="remove"
        >
          {{ $t('remove') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TimeSelectorV2 from '@/components/common/TimeSelectorV2'

export default {
	name: 'CustomOpeningHour',
	components: {
		TimeSelectorV2
	},
	props: {
		seasonalHours: {
			type: Object,
			required: true
		},
		dateIsBlocked: {
			type: Boolean,
			required: true
		},
		scopeIndex: {
			type: Number,
			required: true
		}
	},
	data(){
		return {
			initialStartTime: null,
			initialEndTime: null,
		}
	},
	created() {
		if(typeof this.seasonalHours !== 'undefined' && this.seasonalHours !== null) {
			this.initialStartTime = this.seasonalHours.starthours
			this.initialEndTime = this.seasonalHours.endhours
		}
	},
	methods: {
		emitStartTime(value) {
			const payload = {
				starthours: value,
				scopeIndex: this.scopeIndex
			}
			this.$emit('updateTime', payload)
		},
		emitEndTime(value) {
			const payload = {
				endhours: value,
				scopeIndex: this.scopeIndex
			}
			this.$emit('updateTime', payload)
		},
		remove() {
			this.$emit('removeTime', this.scopeIndex)
		}
	}
}
</script>

<style scoped>

</style>
