<template>
  <v-expansion-panels mandatory>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('general') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-text-field
            v-model="location.label"
            filled
            dense
            :label="$t('label') + ((location && location.uuid) ? '' : ' *' )"
            counter="128"
            required
          >
            <template
              v-if="location.label === 'Minigarðurinn'"
              v-slot:append
            >
              <img
                width="24"
                height="24"
                src="../assets/extras/villi.svg"
              >
            </template>
          </v-text-field>
          <v-text-field
            v-model="location.description"
            filled
            dense
            :label="$t('description') + ' *'"
            counter="128"
            :rules="lengthRule"
            required
          />
          <v-text-field
            v-model="location.phone"
            filled
            dense
            :label="$t('phone')"
            counter="128"
            required
          />
          <v-text-field
            v-model="location.email"
            filled
            dense
            :label="$t('email')"
            counter="128"
            required
          />
          <v-text-field
            v-model="location.taxId"
            filled
            dense
            :label="$t('taxId')"
            counter="128"
            required
          />
          <v-autocomplete
            v-model="preferredChannel"
            filled
            dense
            :label="$t('preferredChannel')"
            :items="channels"
            item-value="key"
            item-text="label"
            persistent-hint
            :hint="$t('preferredChannelInformation')"
          />
          <v-switch
            v-model="location.active"
            :label="location.active ? $t('active') : $t('disabled')"
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('address') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-select
            v-model="location.address.country"
            :items="countries"
            item-text="name"
            item-value="code"
            :label="$t('country')"
            required
            filled
            dense
          />
          <v-text-field
            v-model="location.address.name_line"
            filled
            dense
            :label="$t('fullName')"
          />
          <v-text-field
            v-model="location.address.thoroughfare"
            filled
            dense
            :label="$t('address')"
          />
          <v-text-field
            v-model="location.address.postal_code"
            filled
            dense
            :label="$t('postalCode')"
          />
          <v-text-field
            v-model="location.address.locality"
            filled
            dense
            :label="$t('city')"
          />
          <v-select
            v-model="location.timezone"
            filled
            dense
            :label="$t('timezone')"
            :items="timezones"
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('gps') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-text-field
            v-model="location.gps.lat"
            filled
            dense
            :label="$t('latitude')"
          />
          <v-text-field
            v-model="location.gps.lon"
            filled
            dense
            :label="$t('longitude')"
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('openingHoursForOnlineOrdering') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="location.opening_rule"
              :items="openingRules"
              item-text="text"
              item-value="value"
              :label="$t('openingRule')"
              required
              filled
              dense
            />
          </v-col>
        </v-row>
        <OpeningHours
          :opening-hours="location.opening_hours"
          :opening-rule="location.opening_rule"
          @addNewOpeningHour="addNewOpeningHour"
          @sameAsPrevious="copyOpeningHoursFromPreviousWeekday"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('seasonalOpeningHours') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row dense>
          <v-col
            cols="12"
            lg="5"
            class="py-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedCustomOpeningDay"
                  label="Date"
                  outlined
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="selectedCustomOpeningDay"
                full-width
                :event-color="day => location.closedDates.indexOf(day) !== -1 ? salesCloudColors.red : salesCloudColors.blue"
                :events="customOpeningHourEvents"
								class="datePicker"
              />
            </v-menu>
          </v-col>
          <v-col
            class="d-flex flex-column py-2"
            cols="12"
            lg="7"
          >
            <v-row
              v-if="selectedCustomDateIsClosed"
              justify="center"
            >
              <h4 :style="'color:' + salesCloudColors.red + '; text-transform: uppercase'">
                This day has been closed
              </h4>
            </v-row>
            <v-row
              dense
              v-if="selectedDaySeasonalHours.length > 0"
            >
              <CustomOpeningHour
                v-for="(seasonalHourObject, index) in selectedDaySeasonalHours"
                :key="selectedCustomOpeningDay + index"
                :seasonal-hours="seasonalHourObject"
                :date-is-blocked="selectedCustomDateIsClosed"
                :scope-index="index"
                @updateTime="updateCustomOpeningHours"
                @removeTime="removeCustomOpeningHour"
              />
            </v-row>
            <v-spacer v-if="selectedDaySeasonalHours.length > 0" />
            <v-row dense>
              <v-col
                cols="6"
                class="px-0 py-2"
                align-self="end"
              >
                <v-btn
                  class="px-0"
                  text
                  block
                  @click="addNewCustomOpeningHour"
                  :color="salesCloudColors.blue"
                  :disabled="selectedCustomDateIsClosed"
                >
                  Add time
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="px-0 py-2"
                align-self="end"
              >
                <v-btn
                  class="px-0"
                  block
                  :text="!selectedCustomDateIsClosed"
                  @click="handleSelectedCustomDate"
                  :color="openCustomOpeningDayTextColor"
                >
                  {{ openCustomOpeningDayText }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('bookings') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-select
          :label="$t('excludedTables')"
          :return-object="false"
          outlined
          v-model="location.excludedLocationVariationsFromBookings"
          :items="location.variations"
          item-text="label"
          item-value="uuid"
          multiple
        />
        <v-select
          :label="$t('excludedZones')"
          :return-object="false"
          outlined
          v-model="location.excludedZonesFromBookings"
          :items="zones"
          item-text="name"
          item-value="uuid"
          multiple
        />
        <v-select
          :label="$t('excludedZonesFromOnlineBookings')"
          :return-object="false"
          outlined
          v-model="location.excludedZonesFromOnlineBookings"
          :items="zones"
          item-text="name"
          item-value="uuid"
          multiple
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('periodicCriteria') }}</v-expansion-panel-header>
      <v-expansion-panel-content />
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('customSlots') }}</v-expansion-panel-header>
      <v-expansion-panel-content />
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('slots') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-text-field
            v-if="userIsAdmin && location && location.uuid"
            v-model="slotsManagerURL"
            id="slotsLink"
            readonly
            @click:append="copySlotsLink"
            append-icon="mdi-content-copy"
            filled
            dense
            :label="$t('slotsManagerURL')"
            :hint="$t('slotsManagerURLHint')"
            persistent-hint
          />
          <v-select
            v-if="location && location.uuid"
            v-model="location.ept"
            :items="eptValuesInSeconds"
            item-text="text"
            item-value="value"
            :label="$t('waitTime')"
            filled
            dense
          />
          <v-text-field
            v-model="location.maximumSalesPerSlot"
            @keyup="maxSalesToFloat"
            suffix="ISK"
            filled
            dense
            :label="$t('maxSalesPerSlot')"
          />
          <v-select
            v-model="location.intervalBetweenSlotsInMillis"
            :items="timeIntervalsInMillis"
            item-text="text"
            item-value="value"
            :label="$t('intervalBetweenSlots')"
            filled
            dense
          />
          <v-select
            v-model="location.pauseBetweenSlotsInMillis"
            :items="timeIntervalsInMillis"
            item-text="text"
            item-value="value"
            :label="$t('pauseBetweenSlots')"
            filled
            dense
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="false">
      <v-expansion-panel-header>{{ $t('locationInformation') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        {{ location }}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

	import OpeningHours from "./common/OpeningHours"
	import CustomOpeningHour from '@/components/openingHours/CustomOpeningHour'

	export default {
		name: "LocationInformation",
		components: {
			OpeningHours,
			CustomOpeningHour
		},
    props: {
      location: {
        type: Object,
        default: () => {}
      }
    },
		data() {
			return {
        openingRules: [
					{value: "location_is_open_based_on_opening_hours", text: this.$t('locationIsOpenBasedOnOpeningHours')},
          {value: "location_is_always_open", text: this.$t('locationIsAlwaysOpen')},
          {value: "location_is_always_closed", text: this.$t('locationIsAlwaysClosed')}
				],
        lengthRule: [
          v => (v && v.length <= 128) || this.$t('textMustBeLessThan128Characters'),
        ],
				timezones: [
					'UTC',
					'Atlantic/Reykjavik',
					'Europe/Stockholm',
					'Europe/Copenhagen',
					'Europe/London',
					'Europe/Lisbon',
				],
				timeIntervalsInMillis: [],
				eptValuesInSeconds: [],
				selectedCustomOpeningDay: new Date().toISOString().substring(0, 10),
				proxyClosedDates: [],
			}
		},
		watch: {

		},
		methods: {
			handleSelectedCustomDate() {
				if(this.selectedCustomDateIsClosed) {
					const index = this.location.closedDates.indexOf(this.selectedCustomOpeningDay)
					if (index !== -1) {
						this.location.closedDates.splice(index, 1)
					}
				}
				else(
					this.location.closedDates.push(this.selectedCustomOpeningDay)
				)
			},
			updateCustomOpeningHours(payload) {
				this.selectedDaySeasonalHours = payload
			},
			removeCustomOpeningHour(scopeIndex) {
				const payload = {
					scopeIndex: scopeIndex,
					remove: true
				}
				this.selectedDaySeasonalHours = payload

			},
			addNewCustomOpeningHour() {
				this.location.seasonalHours.push({
					startTimeInMillis: Date.parse(this.selectedCustomOpeningDay + ' 00:00'),
					endTimeInMillis: Date.parse(this.selectedCustomOpeningDay + ' 23:59'),
					starthours: '00:00',
					endhours: '23:59'
				})
			},
			maxSalesToFloat() {
				this.location.maximumSalesPerSlot = this.location.maximumSalesPerSlot.replace(/[^\d.-]/g, '')
      },
      copySlotsLink() {
        let copyText = document.getElementById("slotsLink")

        copyText.select()
        copyText.setSelectionRange(0, 99999)

        document.execCommand("copy")
      },
			addNewOpeningHour(openingHour) {
				if(!this.location.opening_hours || !Array.isArray(this.location.opening_hours) || this.location.opening_hours.length < 1) {
					this.location.opening_hours = []
				}
				this.location.opening_hours.push(openingHour)
			},
			copyOpeningHoursFromPreviousWeekday(weekday) {
				if (!this.location.opening_hours || !Array.isArray(this.location.opening_hours)) {
					return
				}
				const previousWeekday = weekday === '0' ? 6 : ((weekday * 1) - 1)
				const previousWeekdayOpeningHours = this.location.opening_hours.filter(openingHour => (openingHour.day * 1) === previousWeekday)
				this.location.opening_hours = this.location.opening_hours.filter(openingHour => (openingHour.day * 1) !== (weekday * 1))
				if(previousWeekdayOpeningHours.length > 0) {
					for(let i = 0; i < previousWeekdayOpeningHours.length; i++) {
						let openingHour = JSON.parse(JSON.stringify(previousWeekdayOpeningHours[i]))
						openingHour.day = weekday + ''
						this.location.opening_hours.push(openingHour)
					}
				}
			},
		},
    created() {
      let minutesInDay = []
      let eptMinutesInDay = []
      for(let i = 0; i < 1440; i++) {
        let stringValue = ""
        let minutes = Math.floor(i % 60)
        let hours = Math.floor((i / 60) % 24)

        if(hours > 0){
          stringValue = hours +  ' ' + this.$t('hoursAnd') + ' '
        }
        stringValue = stringValue + minutes + ' ' + this.$t('minutes')

        minutesInDay.push({value: i * 60000, text: stringValue})
        eptMinutesInDay.push({value: i * 60, text: stringValue})
      }
      this.timeIntervalsInMillis = minutesInDay
      this.eptValuesInSeconds = eptMinutesInDay

			if(!Array.isArray(this.location.seasonalHours)) {
				this.location.seasonalHours = []
			}
			if(!Array.isArray(this.location.closedDates)) {
				this.location.closedDates = []
			}
    },
    beforeMount() {
      if(!this.location.address) {
        this.location.address = {
          country: ''
        }
      }
      if(!this.location.gps) {
        this.location.gps = {
          lat: '',
          lon: ''
        }
      }
		},
    mounted() {
    },
		computed: {
      preferredChannel: {
        get() {
          if(typeof this.location.preferredChannel !== 'undefined' && this.location.preferredChannel !== null) {
            if(typeof this.location.preferredChannel.uuid !== 'undefined' && this.location.preferredChannel.uuid !== null) {
              return this.location.preferredChannel.uuid
            }
          }
          return this.location.preferredChannel
        },
        set(uuid) {
          this.$emit('updateLocationValue', { key: 'preferredChannel', value: uuid })
          // this.location.preferredChannel = uuid
        }
      },
			customOpeningHourEvents() {
				const uniqueDaysWithCustomHours = this.location.seasonalHours.map(e => new Date(e.startTimeInMillis).toISOString().split('T')[0])
				const allEvents = uniqueDaysWithCustomHours.concat(this.location.closedDates)
				return allEvents.filter((item, index) => allEvents.indexOf(item) === index)
			},
			salesCloudColors() {
				return this.$store.state.salesCloudColors
			},
			openCustomOpeningDayText() {
				if(this.selectedCustomDateIsClosed) {
					return 'Open Day'
				}
				return 'Close Day'
			},
			openCustomOpeningDayTextColor() {
				if(this.selectedCustomDateIsClosed) {
					return this.salesCloudColors.mossGreen
				}
				return this.salesCloudColors.red
			},
			selectedCustomDateIsClosed() {
				return this.location.closedDates.findIndex(d => d === this.selectedCustomOpeningDay) !== -1
			},
			selectedDaySeasonalHours: {
				get() {
					return this.location.seasonalHours.filter(hour =>  new Date(hour.startTimeInMillis).toISOString().substring(0, 10) === this.selectedCustomOpeningDay)
				},
				set(payload) {
					const shallowList = this.location.seasonalHours.filter(hour => new Date(hour.startTimeInMillis).toISOString().substring(0, 10) === this.selectedCustomOpeningDay)
          const listItem = shallowList[payload.scopeIndex]
					if (typeof payload.remove !== 'undefined' && payload.remove && typeof payload.scopeIndex === 'number') {
						listItem.remove = true
						const removalIndex = this.location.seasonalHours.findIndex(element => {
							return element.remove === true
						})
						this.location.seasonalHours.splice(removalIndex, 1)
					}
					else {
						if (typeof payload.starthours !== 'undefined' && payload.starthours !== null) {
							listItem.starthours = payload.starthours
							listItem.startTimeInMillis = Date.parse(this.selectedCustomOpeningDay + ' ' + payload.starthours)
						}
						if (typeof payload.endhours !== 'undefined' && payload.endhours !== null) {
							listItem.endhours = payload.endhours
							listItem.endTimeInMillis = Date.parse(this.selectedCustomOpeningDay + ' ' +  payload.endhours)
						}
					}
				}
			},
      zones() {
        const zoneIndex = []
        const zones = []

        for(let i in this.location.variations) {
          const variation = this.location.variations[i]
          if(variation.zone !== null && variation.zone !== undefined && !zoneIndex.includes(variation.zone.uuid)) {
            zoneIndex.push(variation.zone.uuid)
            zones.push(variation.zone)
          }
        }

        return zones
      },
      periodicCriteria() {
        return [
          {
            label: this.$t('5PlusGets3hours'),
            period: this.$t('17:00-22:30'),
            guests: this.$t('5-200'),
            duration: this.$t('180minutes')
          },
          {
            label: 'Jóla setning',
            period: this.$t('17:30-23:45'),
            guests: this.$t('2-200'),
            duration: this.$t('180minutes')
          },
          {
            label: this.$t('lunch'),
            period: this.$t('11:30-14:00'),
            guests: this.$t('1-200'),
            duration: this.$t('120minutes')
          }
        ]
      },
      customSlots() {
        return [
          {
            label: this.$t('fri-sat'),
            slots: [
              {
                label: this.$t('11:30')
              },
              {
                label: this.$t('13:30')
              },
              {
                label: this.$t('14:00')
              },
              {
                label: this.$t('17:00')
              }
            ]
          },
          {
            label: this.$t('christmas'),
            slots: [
              {
                label: this.$t('11:30')
              },
              {
                label: this.$t('13:30')
              },
              {
                label: this.$t('14:00')
              },
              {
                label: this.$t('17:00')
              }
            ]
          }
        ]
      },
			countries: function() {
				return this.$store.state.countries
			},
      organizationUUID: function() {
        return this.$store.state.organization.uuid
      },
      userIsAdmin () {
        return !!(this.$store.state.user.email.includes('@salescloud.is'))
      },
      slotsManagerURL: function() {
        return 'https://slots.salescloud.is/?organization=' + this.organizationUUID + '&l=' + this.location.uuid
      },
      channels() {
        const channels = this.$store.state.channels.filter(c => c && c.active === true)
        return this.$store.getters.channelsFormatForUiFields(channels)
      }
		}
	}
</script>

<style scoped>
.datePicker {
  height: 450px !important;
}
</style>

